<template>
    <div>
<span ico="el-icon-s-unfold" class='daohangcaidang' @click="changecollagese">{{collapse}}</span>
<el-menu  class="el-menu-vertical-demo"
:unique-opened="true"
 @select="handls"  default-active="1-1-1"  :collapse="isCollapse">
     <el-submenu v-for="it in ui_menu" :key='it.submenu' :index="it.submenu">
          <template slot="title">
               <i :class="it.ico"></i>
                <span  slot="title">{{it.span}}</span>
          </template>
          <!-- 没有找到原因，这样将修改密码的index写死2-1-1   -->
           <el-menu-item  v-for ='it0 in it.menu_item' :key='it0.index' index="2-1-1">{{it0.title}}</el-menu-item>


          <el-submenu v-for="it1 in it.group"  :key='it1.index' :index="it1.index">
              <template slot="title">{{it1.title}}</template>
              <el-menu-item  v-for="it2 in it1.menu_item" :key='it2.index' :index="it2.index" :disabled="it2.show">{{it2.title}}</el-menu-item>
          </el-submenu>
     </el-submenu>
</el-menu>
</div>
</template>
<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
</style>

<script>
    ////将右边的页面先引入
    import  md5 from '../components/common/js/allSchool.js'
    import {PostobjKeySort}  from '../components/common/js/printHelp.js'
    import store  from '../store'
    import new_first from '@/components/new_first.vue'
    import Model_report from '@/components/Model_report.vue'
    import PasswordEdit from '@/components/PasswordEdit.vue'

    export default {
        data() {
            return {
                 isCollapse: true,
                 collapse:'导航菜单  ◀',
                payedit:true,
                ui_menu:
                [{"submenu":"1","ico":'el-icon-user-solid',"span":'供应商管理',"menu_item":[],"group":[
                    {"index":'1-1',"title":'采购业务',"menu_item":[{"index":'1-1-1' ,'title':'线上采购订单',"content":Model_report ,"show":false },
                                                                   {"index":'1-1-2' ,'title':'采购订单查询',"content":Model_report,"show":false},
                                                                   {"index":'1-1-3' ,'title':'采购收货单查询',"content":Model_report,"show":false},
                                                                   {"index":'1-1-4' ,'title':'采购退货单查询',"content":Model_report,"show":false}
                                                                   ] },

                    {"index":'1-2',"title":'对账业务',"menu_item":[{"index":'1-2-1' ,'title':'应付账单查询',"content":Model_report,"show":false},
                    {"index":'1-2-2' ,'title':'对账单查询',"content":Model_report,"show":false}] },

                    //{"index":'1-3',"title":'调价业务',"menu_item":[{"index":'1-3-1' ,'title':'采购调价单',"content":Model_report,"show":false}] },

                    {"index":'1-4',"title":'报表查询',"menu_item":[{"index":'1-4-1' ,'title':'商品销售查询',"content":Model_report,"show":false},
                                                                    {"index":'1-4-2' ,'title':'商品库存查询',"content":Model_report,"show":false},
                                                                    {"index":'1-4-3' ,'title':'商品状态查询',"content":Model_report,"show":false},
                                                                    {"index":'1-4-4' ,'title':'商品销售汇总查询',"content":Model_report,"show":false}]}

                ]},
                {"submenu":"2","ico":'el-icon-s-tools',"span":'系统管理',"menu_item":[{"index":'2-1-1' ,'title':'修改密码',"content":PasswordEdit,"show":"true"},] ,"group":[] }]

                ,
                //是否导航展开
                isCollapse: false
            };
        },
        created(){
           let user= this.userinfo.userinfo;
           //this.getlimits(user);
           this.getTitledisplay();

        },
        computed: {
                userinfo : {
                    get () {
                        return this.$store.state.userinfo
                    }
                },
                editableTabs : {
                get () {
                    return this.$store.state.editableTabs
                }
            },
                },
        methods: {
            getTitledisplay(){
                let _this=this;
                let query={
                    
                        ip:_this.$store.state.ip,
                        unionid:_this.$store.state.unionid,
                        scm_code:_this.$store.state.currentMartId,
                        
                        }
                    let newkey = PostobjKeySort(query)                 
                    let sign= md5.M5(newkey.md5s)
                    //this.$axios.post("/SCM/Login?sign="+sign,newkey.par)
                    this.reqM1Service("/SCM/GetSCMMenu?sign="+sign,newkey.par,'post')
                    .then((res => {
                        console.log("接口返回",res)
                        if( res.data.code==200) {
                            console.log("code=200",res.data.data)
                         _this.ui_menu[0].group.forEach(sub=>{
                            console.log("我进入了，group")
                            sub.menu_item.forEach(sub1=>{
                               
                               let memeitindex= sub1.index;
                               console.log("我进入了，menu_item index",memeitindex)
                               res.data.data.forEach(apiitem=>{
                                console.log("我进入了，apiitem index",memeitindex)
                               if( apiitem.menu_code===memeitindex){
                                console.log("sub1.show=apiitem.apiitem",memeitindex,"/",apiitem.menu_code )
                                sub1.show=apiitem.grant1
                               }
                               })

                            })
                         })

                        }
                       
                       console.log("工具栏修改后，",_this.ui_menu)
                    })).catch(_ => {
                    });

            },


            changecollagese(){this.isCollapse=!this.isCollapse;
            if(this.isCollapse==true){this.collapse='   ▶';
            store.commit('changebodywidth',"width: 100%")
            console.log('bodyWidth',this.$store.state.bodyWidth)
            }
            else{this.collapse='导航菜单  ◀';
            store.commit('changebodywidth',"width: 85%")
            console.log('bodyWidth',this.$store.state.bodyWidth)
            }


            },
            getlimits(s){
                this.reqM1Service('/limits/',{user:s},'get')
               //this.$axios.get('/limits/?user='+s)
               .then((res=>{
                  if(res.status==200){
                      if (res.data.Msg[0].打开==true){
                          this.payedit=false;
                      }
                  } }
                   ))
            },
                 handls(index){

                this.ui_menu.forEach(a=>{

                    if(a.group.length>0)
                    {a.group.forEach(b=>{
                        b.menu_item.forEach(c=>{
                           if(c.index==index) {
                             //  console.log("我是c",c)
                               if (this.editableTabs.length>7){
                                  alert("不能再打开窗口了，先关闭没有使用的窗口！");

                                   return;
                               }
                               store.commit('addtabs',c)
                           }
                        })
                       if (b.index ==index) {
                            console.log("ok"+b.title);
                            if(this.editableTabs.length>7){
                                alert("不能再打开窗口了，先关闭没有使用的窗口！");
                                return;
                            }
                            store.commit('addtabs',b)
                        }
                    })}

                    if(a.menu_item.length>0)
                    { console.log('now check',a.span,index)
                    if (index==='2-1-1'){
                         a.menu_item.forEach(d=>{
                        if(d.index=index)
                {  console.log("我是d",d)
                  store.commit('addtabs',d)}
                    })
                    }
                       }
                })
            }
    }}
</script>
