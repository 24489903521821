<template>
<div>
    <el-row>


      <div class="buttons-div">
      <el-container>
      <el-button v-show="new_button" size="mini" type="success" icon="el-icon-download" plain @click="open_new_sheet_form">新建</el-button>
      <el-button size="mini" type="warning" icon="el-icon-download" plain @click="exportdata">导出</el-button>
      <el-button size="mini" type="primary" icon="el-icon-printer" plain @click="printEvent">打印</el-button>
      <!-- <el-button size="mini" type icon="el-icon-setting">列设置</el-button> -->
      <el-button v-show='confirm_bu' size="mini" type="info" plain icon="el-icon-setting">确认送货</el-button>
      </el-container>
      </div>
    </el-row>
    <!-- v-model="start_end" -->
    <el-row>
     <div style="width:100%">
       <div  v-if="isShow.isShowPickdate" style="display: inline-block; ">
          <el-container>
         <p>日期:  </p>
       <el-date-picker
            size="mini"
            v-model="start_end" 
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
       </el-date-picker>
       </el-container>
       </div>

       <div  v-if="isShow.isShowCategory"  style="display: inline-block; ">
         <el-container>
         <p>类别:  </p>
       <el-input  v-model="Query.clsNo" class="input-with-select" size="mini">
           <el-button slot="append" icon="el-icon-search" @click="cate_dialogVisible=true"></el-button>
       </el-input>
       </el-container>
      </div>

       <div v-if="isShow.isShowSheetno" style="display: inline-block; ">
        <el-container>
           <p>单号:  </p>
               <el-input  v-model="Query.sheetNo" class="input-with-select" size="mini"></el-input>
        </el-container>
       </div>

       <div  v-if="isShow.isShowShop"  style="display: inline-block; ">
         <el-container>
         <p style="width:40px">门店:</p>
        <el-input  v-model="Query.branchNo" class="input-with-select" size="mini">
           <el-button slot="append" icon="el-icon-search" @click="shop_dialogVisible=true"></el-button></el-input>
         </el-container>
       </div>

       <div v-if="isShow.isShowGoods"  style="display: inline-block; ">
         <el-container>
         <p>商品:  </p>
       <el-input  v-model="Query.itemNo" class="input-with-select" size="mini">    </el-input>
         </el-container>
       </div>
       <div  v-if="isShow.isShowGoodsName" style="display: inline-block; ">
         <el-container>
         <p>品名:  </p>
       <el-input  v-model="Query.itemName" class="input-with-select" size="mini">    </el-input>
         </el-container>
       </div>

       <div v-if="isShow.isShowPp" style="display: inline-block; ">
         <el-container><p>品牌:  </p>
       <el-input  v-model="Query.brandNo" class="input-with-select" size="mini">    </el-input>
       </el-container>

       </div>

       <div v-if="isShow.isShowDeliverstatus"  style="display: inline-block; ">
        <el-container>
         <p>送货状态:  </p>
        <el-select v-model="Query.sendStatus" placeholder="请选择" size="mini">
          <el-option
            v-for="item in optionsSh"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
         </el-select>
         </el-container>
         </div>
         <div class="buttons-div" style="display: inline-block; margin-top: 10px " >
           <el-button  icon="el-icon-search" type="mini" @click="get_data" >查询</el-button>
         </div>
     </div>
    </el-row>
    <el-row>
      <!--从这里开始，编辑表格内容。-->
      <div class="grid">
        <template>
         <vxe-toolbar ref="xToolbar" custom >
              <template #buttons>
              <div v-if="doubclick" style="padding-left:5px;color: red;" >双击单号可穿透</div>
              </template>
            </vxe-toolbar>
              <vxe-table
              height="350"
              ref="xTable"
                size="mini"
               @cell-dblclick="cellDBLClickEvent"
                 :data.sync="tableData"
                show-footer
                :footer-method="footerMethod"
                :footer-cell-class-name="footerCellClassName"
                border="full"
                >
          <vxe-table-column type="seq" width="60"></vxe-table-column>
          <vxe-table-column v-for="(item,key,index) in tableData[0]" :key="index" :field="key"  :title="key"  > </vxe-table-column>
            </vxe-table>
          
        </template>
      </div>
    </el-row>
    <!-- 弹出窗口编辑区域 -->
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="门店机构"
         :visible.sync= "shop_dialogVisible"
         width="40%">
        <el-row>
          <div style="display: inline-block; padding-right:10px "><el-button type="warning">取消选择</el-button></div>
          <div style="display: inline-block;  "><el-input style="width:230px;" v-model='dia_shop_select' ></el-input></div>
          <div style="display: inline-block;padding-left:10px  "><el-button type="primary">搜索</el-button></div>
        </el-row>
        <el-row>
           <template>
          <el-table  border :data="tableData_shop"
          height="250"
           @current-change="handleCurrentChange_shop"
           highlight-current-row>
           <el-table-column type="index"  width="50"></el-table-column>
           <el-table-column prop="编码" label='编码' width='180' ></el-table-column>
           <el-table-column prop="名称" label='名称' width='180' ></el-table-column>
          </el-table>
           </template>
        </el-row>
       <span slot="footer" class="dialog-footer">
        <el-button @click="shop_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm_shop">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="类别选择"
         :visible.sync= "cate_dialogVisible"
         width="40%">
        <el-row>
          <div style="display: inline-block; padding-right:10px "><el-button type="warning">取消选择</el-button></div>
          <div style="display: inline-block;  "><el-input style="width:230px;" v-model='dia_cate_select' ></el-input></div>
          <div style="display: inline-block;padding-left:10px  "><el-button type="primary">搜索</el-button></div>
        </el-row>
        <el-row>
           <template>
          <el-table  border :data="tableData_cate"
           @current-change="handleCurrentChange_cate"
           highlight-current-row
           v-loading="tableLoad"
           >
           <el-table-column type="index"  width="50"></el-table-column>
           <el-table-column prop="编码" label='编码' width='180' ></el-table-column>
           <el-table-column prop="名称" label='名称' width='180' ></el-table-column>
          </el-table>
           </template>
        </el-row>
       <span slot="footer" class="dialog-footer">
        <el-button @click="cate_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm_cate">确 定</el-button>
      </span>
    </el-dialog>



</div>
</template>
<script >
import  md5  from '../components/common/js/allSchool.js'
import Statement from '@/components/Statement.vue'
import { export2Excel } from '@/components/common/js/util.js'

import { exportAllDataEvent4 } from '@/components/common/js/util.js'
import { printJson } from '@/components/common/js/printHelp.js'
import {objKeySort} from '@/components/common/js/printHelp.js'
import{dateFormat} from '@/components/common/js/printHelp.js'
//import printJS from 'print-js'
import Form_View from '@/components/Form_View.vue'
import FormEdit from '@/components/FormEdit.vue'
import store  from '../store'
import {PostobjKeySort}  from '../components/common/js/printHelp.js'

 const printStyle = `
        .title {
          text-align: center;
        }
        .my-list-row {
          display: inline-block;
          width: 100%;
        }
        .my-list-col {
          float: left;
          width: 33.33%;
          height: 28px;
          line-height: 28px;
        }
        .my-top,
        .my-bottom {
          font-size: 12px;
        }
        .my-top {
          margin-bottom: 5px;
        }
        .my-bottom {
          margin-top: 30px;
          text-align: right;
        }
        `;

export default {
  components: {
    exportAllDataEvent4,
            export2Excel,
            printJson,
            objKeySort,
            dateFormat
        },
  //提供一个参数给父组件调用；
   props:['title','parameter'],
    data() {
      return {
        doubclick:true,
        tableLoad:false,
        new_button:false,
        confirm_bu:false,
        tableMaxHeight:0,
        tableHeight:0,
        url:null,
        identifyCode:'',
        identifyCodes:'1234567890',
        currentCate:'',
        cate_dialogVisible:false,
        dia_cate_select:null,
        mainSelectshop:'',
        currentShop:null,
        dia_shop_select:'',
        shop_dialogVisible:false,
        tableData_shop:[]  ,
        tableData_cate:[ ],
        urlcol:null,
        //表格数据
              loading:false,
               currentPage:1, //初始页
               pagesize:20,    //    每页的数据
               tableData:[ ],
        //查询条件的值
        start_end:[new Date(),new Date()],
        Query:{

          clsNo:'',
          sendStatus:'',
          itemNo:'',
          itemName:'',
          brandNo:'',
          sheetNo:'',
          branchNo:'',
          beginDate:'',
          endDate:'',
          supcustNo:this.$store.state.userinfo
        },
//查询条件是否显示设置
        isShow:{
          isShowPickdate:false,
          isShowShop:false,
          isShowCategory:false,
          isShowSheetno:false,
          isShowGoods:false,
          isShowGoodsName:false,
          isShowPp:false,
          isShowDeliverstatus:false
          },
        //接收参数
        select_value:'',
        sheetNo:'',
        cData:'',
        Link_col:'',



        optionsSh:[{
          value:"0",
          label:'全部'
        },
        {
           value:"1",
          label:'未送货'
        },
        {
           value:"2",
          label:'已送货'
        }],

        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
        },
///页面加载完后执行
          mounted(){
            this.initselect();
            this.$nextTick(() => {
            this.tableHeight = window.innerHeight - 200;
            this.tableMaxHeight=window.innerHeight - 50;
        // 后面的50：根据需求空出的高度，自行调
        })
          },
          methods: {
             footerCellClassName ({ rowIndex, column, columnIndex }) {
              if (columnIndex === 0) {
                if (rowIndex === 0) {
                  return 'col-blue'
                } else {
                  return 'col-red'
                }
              }
            },

 ///计算合计
        footerMethod ({ columns, data }) {
              return [
                columns.map((column, columnIndex) => {
                  if (columnIndex === 0) {
                    return '合计'
                  }
                  if (['数量', '箱数','金额','销售数量','销售金额','对账货款','应付金额','未付金额',
                  '对账费用','对账返利','对账扣补','对账预付','对账优惠','对账应付'].includes(column.property)) {
                    return this.$utils.sum(data, column.property)
                  }
                  return ''
                })
              ]
            },
   printEvent () {
            let topHtml = `
                    <h1 class="title">`+this.title+`</h1>
                    <div class="my-top">
                      <div class="my-list-row">
                      <div class="my-list-col">供应商：`+ this.Query["supcustNo"]+`</div>                      
                      <div class="my-list-col">开始日期：`+ this.Query.beginDate+`</div>                     
                        <div class="my-list-col">结束日期：`+ this.Query.beginDate+`</div>
                        <div class="my-list-col">门店`+ this.Query.branchNo+`</div>                   
                      </div>
                    </div>
                    `;
             this.$refs.xTable.print({
                sheetName: '',
                style: printStyle,
                mode:"all",             
                beforePrintMethod: ({content}) => {
                    // 拦截打印之前，返回自定义的 html 内容
                //    return topHtml + content + bottomHtml
                return topHtml + content 
                }
            })

            },

             bccelldblclick(row, column, cell, event) {
             //  console.log("column" ,column)
              // console.log("urlcol " ,this.urlcol)
      //点击对应的单元格取值

      if( this.urlcol !=null && this.urlcol.indexOf(column.label)>-1){
     //   console.log(row[column.label])
        this.show_detail(row[column.label]);
      }
      },
      
        //双击单元格
      cellDBLClickEvent ({ column,row }) {
              console.log(`单元格双击${column.title}`,row["单号"])
              if (column.title=='线上单号' ||column.title=='单号' || column.title=='收货单号' || column.title=='订单号'  )
              {
                this.show_detail(row[column.title]);
              }
            },



            get_data(){
              
              let _this=this;
              _this.loading=true;
              _this.Query.beginDate=dateFormat('YYYY-mm-dd',_this.start_end[0]);
              _this.Query.endDate=dateFormat('YYYY-mm-dd',_this.start_end[1]);
              _this.Query["ip"]=_this.$store.state.ip
              _this.Query["supcustNo"]=_this.$store.state.superCode
              _this.Query["unionid"]=_this.$store.state.unionid
              _this.Query["scm_code"]=_this.$store.state.currentMartId
              var newkey = objKeySort(_this.Query)
              let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }

              //let tmp = md5.tms()
              //let vars="beginDate=1998-01-10&endDate=2022-10-12&supcustNo=10610"
              let sign= md5.M5(md5s)
              console.log("newkey",newkey)
              console.log("url:",_this.url)
              console.log("sign:",sign)
              console.log("par:",par)

              _this.reqM1Service(_this.url+sign,par,'post')
              //_this.$axios.post(_this.url+sign,par)
              .then((res=>{
                console.log("获取单据数据",res.data)
                if(res.data.code==200){
                    _this.tableData=res.data.data
                    _this.loading=false;
                   //_this.urlcol=res.data.urlCol

                }
                }
              ))}
            ,
            open_new_sheet_form(){

              this.identifyCode="";
              this.makeCode(this.identiyfyCodes,4);
              console.log(this.title,this.identifyCode)

              switch(this.title){
                 case '线上采购订单':{
                   store.commit('addtabs',{title:"线上采购订单编辑",index:this.identifyCode,content:FormEdit,parameter:'A'})
                 break;
                 }
            }},
            show_detail(val){

               this.identifyCode="";
               this.makeCode(this.identiyfyCodes,4);
               let trans=val.substring(0,2)

               switch(trans){
                 case 'DP':{
                   store.commit('addtabs',{title:"对账单明细",index:this.identifyCode,content:Statement,parameter:val})
                 break;
                 }
                 case 'PI':{
                    store.commit('addtabs',{title:"采购收货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'WP':{
                    store.commit('addtabs',{title:"线上采购订单明细",index:this.identifyCode,content:FormEdit,parameter:val})
                    break;
                 }
                 case 'PO':{
                    store.commit('addtabs',{title:"采购订单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'RO':{
                    store.commit('addtabs',{title:"采购退货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
               }




            },
            ///制造随机数
            radomNum(min,max){
                return Math.floor(Math.random()*(max-min)+min);
            },

            makeCode(o,l){
                for (let i=0 ;i<l;i++){
                    this.identifyCode += this.identifyCodes[
                        this.radomNum(0,this.identifyCodes.length)
                        ];
                }
            },


            confirm_shop(){
              this.Query.branchNo=this.currentShop;

              this.shop_dialogVisible = false;

            },
            //门店选择事件
            handleCurrentChange_shop(val) {
              //console.log(val);
        this.currentShop=val.编码

      },
                  handleCurrentChange_cate(val) {
        this.currentCate=val.编码
      },
      confirm_cate(){
        this.Query.category=this.currentCate;
        this.cate_dialogVisible=false;
      },

            show_dia_shop(){this.shop_dialogVisible=true},

            handleSelect(index, row) {
     //   console.log(index, row);
      //  console.log(row[this.Link_col])
      },
      pj(datab){
        printJson(datab);
      },

            //导出数据
            exportdata(){
              console.log("测试",this.tableData)
              if(this.tableData.length>0){
                let clo_key=[];
                for (var p1 in this.tableData[0])
                {if(this.tableData[0].hasOwnProperty(p1))
                clo_key.push({title:p1,key:p1})
                }
                  exportAllDataEvent4(this.title,this.$refs.xTable,this.tableData)
                  //export2Excel(clo_key,this.tableData,this.title)
              }
              else{
                confirm("没有数据可以导出");
              }
              },
            //表格翻页时候触发
            handleSizeChange: function (size) {
                this.pagesize = size;
           //     console.log(this.pagesize)  //每页下拉显示数据
            },
            handleCurrentChange: function(currentPage){
                this.currentPage = currentPage;
             //   console.log(this.currentPage)  //点击第几页
            },
            initselect(){
              let query={
                ip:this.$store.state.ip,
                unionid:this.$store.state.unionid,
                scm_code:this.$store.state.currentMartId,
                supcustNo:this.$store.state.superCode,
              }
              let newkey = PostobjKeySort(query)
                  
                   let sign= md5.M5(newkey.md5s)


              this.reqM1Service('/SCM/GetBranch?sign='+sign,newkey.par,'post')
              //this.$axios.post('/SCM/GetBranch?sign='+sign,newkey.par)
              .then((res=>{
                if(res.data.code==200){
                //  console.log(res.data.data)
                    this.tableData_shop=res.data.data
                }
                }
              ))
              this.reqM1Service('/SCM/GetCls?sign='+sign,newkey.par,'post')
              //this.$axios.post('/SCM/GetCls?sign='+sign,newkey.par)
              .then((res=>{
               console.log("res cate" ,res)
               if(res.data.code==200){
               //   console.log(res.data.data)
                    this.tableData_cate=res.data.data
                }
                }
              ))


              //this.Query.sheetno=this.parameter;

              switch (this.title){
                 case '线上采购订单':{
                   this.new_button=true;
                  this.urlcol=["线上单号","线下单号"]
                  this.url='/SCM/GetOnLinePO?sign=';
                  this.Link_col='线上单号';
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=false;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=false;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  break;
                }
                case '采购订单查询':
                  {
                  this.url='/SCM/GetPO?sign=';
                  this.Link_col='单号';
                  this.urlcol=["单号"]
                  this.confirm_bu=true;
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=false;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=true;
                  break;
                  }

                 case '采购收货单查询':{
                  this.url='/SCM/GetPI?sign=';
                  this.urlcol=["收货单号","订单号"]
                  this.Link_col='单号';
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=true;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  break;
                }
                case '采购退货单查询':{
                  this.url='/SCM/GetRO?sign='
                  this.urlcol=["退货单号","收货单号"]
                  this.Link_col='单号'
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=true;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  break;
                }
                case '应付账单查询':{
                  this.url='/SCM/GetShouldPay?sign=';
                  this.Link_col='单号';
                  this.urlcol=["单号"]

                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=false;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=false;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  break;
                }
                case '对账单查询':{
                  this.url='/SCM/GetDP?sign=';
                  this.Link_col='单号';
                  this.urlcol=["单号"]

                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=false;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=true;
                  this.isShow.isShowGoods=false;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  break;
                }
                 case '商品销售查询':{
                  this.url='/SCM/GetItemSale?sign=';
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=false;
                  this.isShow.isShowGoods=true;
                  this.isShow.isShowGoodsName=true;
                  this.isShow.isShowPp=true;
                  this.isShow.isShowDeliverstatus=false;
                  this.doubclick=false;
                  break;
                }
                 case '商品库存查询':{
                  this.url='/SCM/GetItemStock?sign=';
                  this.isShow.isShowPickdate=false;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=false;
                  this.isShow.isShowGoods=true;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  this.doubclick=false;
                  break;
                }
                 case '商品状态查询':{
                   this.url='/SCM/GetItemStatus?sign=';
                  this.isShow.isShowPickdate=false;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=false;
                  this.isShow.isShowSheetno=false;
                  this.isShow.isShowGoods=true;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=false;
                  this.isShow.isShowDeliverstatus=false;
                  this.doubclick=false;
                  break;
                }
                 case '商品销售汇总查询':{
                   this.url='/SCM/GetItemSaleSum?sign=';
                  this.isShow.isShowPickdate=true;
                  this.isShow.isShowShop=true;
                  this.isShow.isShowCategory=true;
                  this.isShow.isShowSheetno=false;
                  this.isShow.isShowGoods=false;
                  this.isShow.isShowGoodsName=false;
                  this.isShow.isShowPp=true;
                  this.isShow.isShowDeliverstatus=false;
                  this.doubclick=false;
                  break;
                }

              }
            },
        }
        }
</script>
<style >
.buttons-div{
  padding: 0 10px;
}


.el-table th {
  padding: 5px 0 !important;
}
.el-table td {
  padding: 5px 0 !important;
}

.el-input{width: 130px;}
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner
 {
    width: 250px;
}
p{padding-left: 10px;padding-right: 7px;}
</style>