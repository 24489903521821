
<template>
<div class="fe">
    <el-row>
      <el-col :span="24" >
          <el-button type="mini" @click="opersheet">新增</el-button>
          <el-button type="mini" :disabled='sub_but' @click="click_save">保存</el-button>
          <el-button class="" type="mini" :disabled='sub_but' @click="submit_sheet">审核</el-button>        
          <span class="title2"> 模版标题</span>
        <el-input    v-model="tableData.sampletitle" style="margin-right: 5px; width: 250px;" size="mini">
            </el-input>
            <el-button type="mini" :disabled='sub_but' @click="save_sample">保存模版</el-button>
            <el-button type="mini" :disabled='sub_but' @click="import_sample">导入模版</el-button>
     

        </el-col>
    </el-row>
 <el-row>
      <el-col class="left-span" :span="3">
          <span >供应商：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <div >{{tableData.Customer}}</div>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >单号：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >{{tableData.SheetNo}}</span>
      </el-col>
 </el-row>
 <el-row>
      <el-col class="left-span" :span="3">
          <span >机构：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >
               <el-input   v-model="tableData.BranchNo" style="width: 150px;" size="mini">
           <el-button slot="append" icon="el-icon-search" @click="Branch_dialogVisible=true"></el-button>
       </el-input>
              </span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >交货日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >
           
              <el-date-picker

              v-model="tableData.SendDate"
      type="date"
      placeholder="选择日期"

      size="mini"

     >
    </el-date-picker>

          </span>
      </el-col>
    </el-row>

   <el-row>
      <el-col class="left-span" :span="3">
          <span >制单日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <div >{{tableData.OperDate}}</div>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >合计金额：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <div >{{tableData.Total}}</div>
      </el-col>
   </el-row>
     <el-row>
      <el-col class="left-span" :span="3">
          <span >描述：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >{{tableData.Description}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >审核状态：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span  v-if="tableData.Status==='已审核'" style="color:red" >{{tableData.Status}}</span>
           <span  v-else style="color:black" >{{tableData.Status}}</span>
      </el-col>
  </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >备注：</span>
      </el-col>
      <el-col class="left-span-content" :span="20">
          <span ><el-input v-model="tableData.Memo" placeholder="" size="mini" style="float:left; width:99%;"></el-input></span>
      </el-col>
   </el-row>

  <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
<body>
    <el-row>
      <el-col :span="24">


          <el-input size="mini" placeholder="输入商品条码"  @keyup.enter.native="add_goods"  v-model="quickGoods" style="padding:0 10px"></el-input>
          <el-button type="mini" :disabled='sub_but' @click="add_goods">快速添加</el-button>
          <el-button type="mini" :disabled='sub_but' @click="showOper=true">删除商品</el-button>
      </el-col>
    </el-row>
        <el-table :data="tableData.Items" border class="tb-edit"
        :max-height="tableMaxHeight"
        :height="tableHeight"
        highlight-current-row
        style="width:90%"
        ref="mainTable"
         >
          <el-table-column label="操作" v-if="showOper">
           <template slot-scope="scope">
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
      </template>
          </el-table-column>
          <el-table-column
          type="index"  width="40" >


        </el-table-column>
            <el-table-column label="货号" width="120" prop="Code">
            </el-table-column>
            <el-table-column label="自编码" width="120" prop='Subcode'>
            </el-table-column>
            <el-table-column label="品名"  show-overflow-tooltip width="180" prop='GoodsName'>
            </el-table-column>
            <el-table-column label="箱数" width="80" >
                <template slot-scope="scope">
                    <el-input size="mini" style="float:left; width:99%;" @keyup.native="changeAmount('LargeNum',scope.row) "
                     v-model="scope.row.LargeNum" placeholder="请输入内容" @change="handleEdit(scope.$index,'LargeNum', scope.row)"
                    :ref='scope.row.id'


                      ></el-input> <span>{{scope.row.LargeNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="数量" width="80" >
                <template slot-scope="scope">
                    <el-input size="mini" style="float:left; width:99%;" @keyup.native="changeAmount('Num',scope.row)"
                     v-model="scope.row.Num" placeholder="请输入内容"
                      @keyup.enter.native="jump_next(scope.$index)"
                     @change="handleEdit(scope.$index,'Num', scope.row)"></el-input> <span>{{scope.row.Num}}</span>
                </template>
            </el-table-column>
            <el-table-column label="赠品数量" width="80" >
                <template slot-scope="scope">
                    <el-input size="mini" style="float:left; width:99%;"  v-model="scope.row.GiftsNum" placeholder="请输入内容" ></el-input> <span>{{scope.row.GiftsNum}}</span>
                </template>

            </el-table-column>
            <el-table-column label="单价"  show-overflow-tooltip width="80" prop='Price'>
            </el-table-column>
            <el-table-column label="金额"  show-overflow-tooltip width="80" prop='Total'>
            </el-table-column>
            <el-table-column label="规格"  show-overflow-tooltip width="80" prop='Spec'>
            </el-table-column>

            <el-table-column label="单位"  show-overflow-tooltip width="50" prop='Unit'>
            </el-table-column>
            <el-table-column label="本周销量"  show-overflow-tooltip width="80" prop='CurrentWeekSale'>
            </el-table-column>
            <el-table-column label="上周销量"  show-overflow-tooltip width="80" prop='LastWeekSale'>
            </el-table-column>
            <el-table-column label="本月销量"  show-overflow-tooltip width="80" prop='CurrenMonthSale'>
            </el-table-column>
            <el-table-column label="上月销量"  show-overflow-tooltip width="80" prop='LastMonthSale'>
            </el-table-column>
        </el-table>

</body>
<!-- 门店选择窗口 -->
<el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="门店机构"      
         :visible.sync= "Branch_dialogVisible"
         width="40%">
        <el-row>
          <div style="display: inline-block; padding-right:10px "><el-button type="warning">取消选择</el-button></div>
          <div style="display: inline-block;  "><el-input style="width:230px;" v-model='tableData.BranchNo' ></el-input></div>
          <div style="display: inline-block;padding-left:10px  "><el-button type="primary">搜索</el-button></div>
        </el-row>
        <el-row>
           <template>
          <el-table  border :data="tableData_shop"
           @current-change="handleCurrentChange_shop"
           height="250"
           highlight-current-row>
           <el-table-column type="index"  width="50"></el-table-column>
           <el-table-column prop="编码" label='编码' width='180' ></el-table-column>
           <el-table-column prop="名称" label='名称' width='180' ></el-table-column>
          </el-table>
           </template>
        </el-row>
       <span slot="footer" class="dialog-footer">
        <el-button @click="Branch_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm_shop">确 定</el-button>
      </span>
    </el-dialog>


      <el-dialog
      v-dialogDrag
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="订单模版"
         :visible.sync= "sample_dialogVisible"
         width="40%">
        <el-row>
         
        </el-row>
        <el-row>
           <template>

            
          <el-table  border :data="tableData_sample"
          height="250"
           @current-change="handleCurrentChange_sample"
           highlight-current-row>
           <el-table-column type="index"  width="50"></el-table-column>
          
           <el-table-column prop="sampleTitle" label='标题' width='350' ></el-table-column>
           <el-table-column prop="" label='删除' width='95' >
            <template slot-scope="scope">
            <el-button type="warning" @click="delete_sample(scope.$index,scope.row)">删 除</el-button>
        </template>
           </el-table-column>
          </el-table>
           </template>
        </el-row>
       <span slot="footer" class="dialog-footer">
        
        <el-button @click="sample_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm_sample">确 定</el-button>
      </span>
    </el-dialog>




   
    <!-- 商品选择窗口 -->
    <el-dialog v-dialogDrag
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="商品选择"
         :visible.sync= "goods_dialogVisible"
         width="60%">
        <el-row>

          <div style="display: inline-block;  "><el-input style="width:230px;" v-model='goodsDiaQuey' ></el-input></div>
          <div style="display: inline-block;padding-left:10px  "><el-button type="primary" @click="select_goods()">搜索</el-button></div>
        </el-row>
        <el-row>
           <template>
          <el-table ref = "multipleTable"
           border :data="tableData_goods"
           @selection-change="handleSelectionChange"
           max-height="300"
           highlight-current-row>
            <el-table-column type="index"  width="50"></el-table-column>
            <el-table-column  type="selection"   width="55">
            </el-table-column>
            <el-table-column label="货号"  show-overflow-tooltip width="100" prop='货号'>
            </el-table-column>
            <el-table-column label="自编码"  show-overflow-tooltip width="80" prop='自编码'>
            </el-table-column>
            <el-table-column label="品名"  show-overflow-tooltip width="180" prop='品名'>
            </el-table-column>

            <el-table-column label="库存"  show-overflow-tooltip width="80" prop='库存'>
            </el-table-column>
            <el-table-column label="商品状态"  show-overflow-tooltip width="80" prop='状态'>
            </el-table-column>
            <el-table-column label="进货价"  show-overflow-tooltip width="80" prop='进价'>
            </el-table-column>
            <el-table-column label="促销进价"  show-overflow-tooltip width="80" prop='促销进价'>
            </el-table-column>

            <el-table-column label="规格"  show-overflow-tooltip width="80" prop='规格'>
            </el-table-column>
            <el-table-column label="进货规格"  show-overflow-tooltip width="80" prop='进货规格'>
            </el-table-column>

            <el-table-column label="单位"  show-overflow-tooltip width="50" prop='Unit'>
            </el-table-column>
            <el-table-column label="本周销量"  show-overflow-tooltip width="80" prop='本周销量'>
            </el-table-column>
            <el-table-column label="上周销量"  show-overflow-tooltip width="80" prop='上周销量'>
            </el-table-column>
            <el-table-column label="本月销量"  show-overflow-tooltip width="80" prop='本月销量'>
            </el-table-column>
            <el-table-column label="上月销量"  show-overflow-tooltip width="80" prop='上月销量'>
            </el-table-column>
          </el-table>
           </template>
        </el-row>
       <span slot="footer" class="dialog-footer">
        <el-button @click="goods_dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm_goods">确 定</el-button>
      </span>
    </el-dialog>

</div>
</template>

<script>
import {dateFormat} from '@/components/common/js/printHelp.js'
import  md5  from '../components/common/js/allSchool.js'
import {objKeySort}  from '../components/common/js/printHelp.js'
import store  from '../store'
import {PostobjKeySort}  from '../components/common/js/printHelp.js'

    export default {
        props:['title','parameter'],
         components: {dateFormat,
         objKeySort

         },
        data() {
            return{
                timer:0,
                save_status:false,
                tableMaxHeight:0,
                tableHeight:0,
                sub_but:false,
                sav_but:false,
                multipleSelection: [],
                Query:'',
                Branch_dialogVisible:false,
                goods_dialogVisible:false,
                currentTarget:'',
                quickGoods:'',
                showOper:false,
                goodsDiaQuey:'',//商品查询条件
                currentSample:'',//当前的模版
                tableData_sample:[],
                sample_dialogVisible:false,
                tableData:
                {
                   Customer:this.$store.state.superName,
                   SheetNo:'WP',
                   BranchNo:'00',
                   Total:0.00,
                   SendDate:dateFormat('YYYY-mm-dd',new Date(new Date().setMonth(new Date().getMonth()+1))),
                   OperDate:dateFormat('YYYY-mm-dd',new Date()),
                   Description:"供应商["+this.$store.state.superName+"]自助制单",
                   Status:'',
                   Memo:'',
                   sampletitle:'',
                   sampleflag:'0',
                     Items:[]
            },
            tableData_shop:[]  ,
            tableData_goods:[],
            tableData_sample:[],

        }},
        //页面启动加载
        mounted(){
            this.initselect();
            this.openOldsheet();
            //让表格根据窗口适应高度
            this.$nextTick(() => {
            this.tableHeight = window.innerHeight - 435;
            this.tableMaxHeight=window.innerHeight - 50;
        // 后面的50：根据需求空出的高度，自行调
        })
          },
        methods:{
            opersheet(){
                this.sub_but=false;
                this.tableData.SheetNo="WP"
                this.tableData.SendDate=dateFormat('YYYY-mm-dd',new Date(new Date().setMonth(new Date().getMonth()+1))),
                this.tableData.OperDate=dateFormat('YYYY-mm-dd',new Date());
                this.tableData.Total=0.00
                this.tableData.Memo=''
                this.tableData.Status=''
                this.tableData.Items=[]


            },
            handleCurrentChange_sample(val) {
              //console.log(val);
              try{
                this.currentSample=""
                
                this.currentSample=val.sampleTitle                                               
               console.log("currentSample",this.currentSample);
              }
              catch (e) {
            console.log("handleCurrentChange_sample",e)
                    }
      },
      import_sample(){

        let _this=this
        this.sample_dialogVisible=true;
        console.log("open sample form.....")

        let Query={}
              
              Query["ip"]=_this.$store.state.ip
              Query["supcustNo"]=_this.$store.state.superCode
              Query["unionid"]=_this.$store.state.unionid
              Query["scm_code"]=_this.$store.state.currentMartId
              Query["sampleflag"]='1'
              var newkey = objKeySort(Query)
              let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            let sign= md5.M5(md5s)
            console.log("xxxxxx",par)
        //获取订单模版的列表
        this.reqM1Service('/SCM/GetOnLinePO?sign='+sign,par,'post')
               //this.$axios.get('/limits/?user='+s)
               .then((res=>{
                  if(res.status==200){
                      console.log("ordersample",res.data)
                    this.tableData_sample=res.data.data
                  } }
                   ))

      },
      //调用模版
      confirm_sample()
      {
        let _this=this;
        let query={
               
               supcustNo:_this.$store.state.superCode,
               unionid:_this.$store.state.unionid,
               ip:_this.$store.state.ip,
               scm_code:_this.$store.state.currentMartId,
               sampletitle:_this.currentSample,
               sampleflag:'1',
               branchNo:_this.tableData.BranchNo

            }
            var newkey = objKeySort(query)
             let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }


            let sign= md5.M5(md5s)
            let url='/SCM/GetOnLinePODetail?sign=';

            console.log('par',par)
             this.reqM1Service(url+sign,par,'post')
            //this.$axios.post(url+sign,par)
            .then((res=>{
                 console.log("获取了订单明细,",res.data)

                if(res.data.code===200){
                _this.sub_but=false;
                _this.tableData.sampletitle=res.data.data.sampleTitle,    
                _this.tableData.SheetNo="WP"
                _this.tableData.SendDate=dateFormat('YYYY-mm-dd',new Date(new Date().setMonth(new Date().getMonth()+1))),
                _this.tableData.OperDate=dateFormat('YYYY-mm-dd',new Date());
                _this.tableData.Total=0.00
                _this.tableData.Memo=''
                _this.tableData.Status=''
                _this.tableData.Items=[]
                res.data.data.items.forEach(row => {
                                _this.tableData.Items.push({
                                Code: row.货号,
                                SubCode:row.自编码,
                                GoodsName: row.品名,
                                LargeNum:row.箱数,
                                Num:row.数量,
                                GiftsNum:row.赠品数,
                                Price:row.价格,
                                Total:row.金额,
                                QtySpec:row.进货规格,
                                Spec:row.规格,
                                StockQty:row.库存,
                                Unit:row.单位,
                                CurrentWeekSale:row.本周销量,
                                LastWeekSale:row.上周销量,
                                CurrenMonthSale:row.本月销量,
                                LastMonthSale:row.上月销量,
                                Memo:row.备注,}

                                )
                                }
                                )
                                _this.getTotal()
                _this.sample_dialogVisible=false


            }
            else
            {
                alert(res.data.message) ;
            }
        
        
        
        }))
            console.log("detail is ",_this.tableData)

      },
      delete_sample(index,e){
              console.log("delete  is ",e);
              let _this=this            
        let Query={}
              
              Query["ip"]=_this.$store.state.ip
              Query["supcustNo"]=_this.$store.state.superCode
              Query["unionid"]=_this.$store.state.unionid
              Query["scm_code"]=_this.$store.state.currentMartId
              Query["sampleflag"]='1'
              Query["sampletitle"]=e.sampletitle
              var newkey = objKeySort(Query)
              let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            let sign= md5.M5(md5s)
            console.log("DelPO",par)
        //获取订单模版的列表
        this.reqM1Service('/SCM/DelSample?sign='+sign,par,'post')
               //this.$axios.get('/limits/?user='+s)
               .then((res=>{
                  if(res.status==200){
                      console.log(" delete ordersample",res.data)  
                      _this.tableData_sample.splice(index,1)                 
                  } }
                   ))

      }
,
            //订单模版保存
            save_sample(){
                if(this.tableData.sampletitle===''){
                    alert("模版名称不能为空!");
                    return;
                }
                this.savePo('1');
                clearTimeout(this.timer);  //清除延迟执行

                this.timer = setTimeout(()=>{   //设置延迟执行
                    console.log('ok');
                    if(this.save_status===true){
                console.log("save scuess")
                    alert("保存成功！");
                }
                },2000)
            },
            //auto jump_next
            jump_next(index){
                console.log("我按了回车！",this.$refs.mainTable)
                if (index+1!=this.tableData.Items.length){
                    this.$refs.mainTable.setCurrentRow(this.$refs.mainTable.data[index+1])
                    // this.$refs[Object.keys(this.$refs)[index + 1]].focus();
                }

            },
            // confirm sheet
            submit_sheet(){
                if(this.savePo()===false){
                    return
              }
            let query={
               sheetNo:this.tableData.SheetNo,
               supcustNo:this.$store.state.superCode,
               unionid:this.$store.state.unionid,
               ip:this.$store.state.ip,
               scm_code:this.$store.state.currentMartId

            }
            var newkey = objKeySort(query)
             let par={};
              let md5s='';

              for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }


            let sign= md5.M5(md5s)
            let url='/SCM/SubmitOnlinePO?sign='

            console.log('par',par)
             this.reqM1Service(url+sign,par,'post')
            //this.$axios.post(url+sign,par)
            .then((res=>{
                 console.log(res)
                if(res.data.code===200){
                    this.tableData.Status='已审核';
                    alert("审核成功！")
                    this.sub_but=true;


            }}))
        },
            click_save(){
                this.savePo('0');


                clearTimeout(this.timer);  //清除延迟执行

                this.timer = setTimeout(()=>{   //设置延迟执行
                    console.log('ok');
                    if(this.save_status===true){
                  console.log("save scuess")
                     alert("保存成功！");
              }
                },2000)
            },
            savePo(savemodel){
                let _this=this;
                _this.tableData.sampleflag=savemodel;
                store.commit('changeSavestate')
            if (_this.tableData.Items.length<1){
                alert("没有数据可以提交！");
                 _this.save_status= false;

                return false;
            }


            _this.tableData.Items.forEach((row)=>{
                console.log("iam row ",row)
              if(row.Num===0)
              {
                  alert("货号"+row.Code+"没有给定订货数量！");
                   _this.save_status= false;

                  return false;
              }
            })

            let list=JSON.stringify(_this.tableData.Items)
            console.log('list',list)
            let post_data={}
            post_data["items"]=list;
            post_data["supcustNo"]=_this.$store.state.superCode;
            post_data["sheetNo"]=_this.tableData.SheetNo;
            post_data["branchNo"]=_this.tableData.BranchNo;
            post_data["total"]=_this.tableData.Total;
            post_data["sendDate"]=_this.tableData.SendDate ;
            post_data["operDate"]=_this.tableData.OperDate;
            post_data["description"]=_this.tableData.Description;
            post_data["memo"]=_this.tableData.Memo;
            post_data["ip"]=_this.$store.state.ip
            post_data["scm_code"]=_this.$store.state.currentMartId
            post_data["unionid"]=_this.$store.state.unionid
            post_data["sampleflag"]=_this.tableData.sampleflag
            post_data["sampletitle"]=_this.tableData.sampletitle


            let newkey = objKeySort(post_data)
            let par={};
            let md5s='';
             for (let i in newkey)
            {
              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }
            }
            par["items"]=JSON.parse(par["items"])
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            console.log("md5s",md5s)
            let sign= md5.M5(md5s)                       
            let url='/SCM/SaveOnLinePO?sign=';            
            console.log('par save sheet',par)
             _this.reqM1Service(url+sign,par,'post')
           //_this.$axios.post(url+sign,par)
            .then((res=>{
                 console.log(res)
                if(res.data.code==200){

                    _this.tableData.SheetNo=res.data.data
                    _this.save_status= true;

            }else{
                 _this.save_status= false;
            }
            }))
            },
            ///在商品选择窗口重新搜索
            select_goods(){this.initgoods(this.goodsDiaQuey)},

            findval(target, source){
                if (source.length<1)
                {
                    return false;
                }
                console.log("source",source,target)
                for ( let i in source){
                    console.log(target,source[i].Code)
                  if(source[i].Code===target){
                      return true;
                  }
                }
                return false;
            },
            confirm_goods(){
                console.log(this.$refs.multipleTable.selection)
                let rows=this.$refs.multipleTable.selection; //选中行信息；
                rows.forEach((row) => {
                    if (this.tableData.Items.length>0){
                       //console.log("enter fainval",row.货号 )
                         if( this.findval(row.货号,this.tableData.Items) === false ) //如果找不到就添加
                         {
                             this.tableData.Items.push({
                                Code: row.货号,
                                SubCode:row.自编码,
                                GoodsName: row.品名,
                                LargeNum:0,
                                Num:0,
                                GiftsNum:0,
                                Price:row.进价,
                                Total:0.00,
                                QtySpec:row.进货规格,
                                Spec:row.规格,
                                StockQty:row.库存,
                                Unit:row.单位,
                                CurrentWeekSale:row.本周销量,
                                LastWeekSale:row.上周销量,
                                CurrenMonthSale:row.本月销量,
                                LastMonthSale:row.上月销量,
                                Memo:row.状态,
                              }

                              )
                         }

                    }
                    else{
                        this.tableData.Items.push({
                                Code: row.货号,
                                SubCode:row.自编码,
                                GoodsName: row.品名,
                                LargeNum:0,
                                Num:0,
                                GiftsNum:0,
                                Price:row.进价,
                                Total:0.00,
                                QtySpec:row.进货规格,
                                Spec:row.规格,
                                StockQty:row.库存,
                                Unit:row.单位,
                                CurrentWeekSale:row.本周销量,
                                LastWeekSale:row.上周销量,
                                CurrenMonthSale:row.本月销量,
                                LastMonthSale:row.上月销量,
                                Memo:row.状态,
                              }
                              )
                    }
                });
            },
            handleDelete(index, row){

                this.tableData.Items.splice(index,1);
                this.getTotal();

            },
            add_goods(){
                this.showOper=false;
                this.initgoods(this.quickGoods);

            },

            initgoods(val){
                console.log("提交的商品",val)
                
                let Query={
                    scm_code:this.$store.state.currentMartId,
                        supcustNo:this.$store.state.superCode,
                        branchNo:this.tableData.BranchNo,
                        ip:this.$store.state.ip,
                        unionid:this.$store.state.unionid
                    }
                    if(val!='')
                    {
                       // let list=JSON.stringify(val) 
                        Query['itemName']=val;
                    }
                   


                    let newkey = objKeySort(Query);
                    let par={};
                    let md5s='';
                     for (let i in newkey)
                    {

                      if (newkey[i]!='')
                      {
                        md5s+=i+"="+newkey[i]+"&";
                        par[i]=newkey[i];
                      }

                    }
                    if(md5s.length>1){
                      md5s=md5s.slice(0,-1)
                    }
                    let sign= md5.M5(md5s)
                    console.log('par',par)

            //   let tmp = md5.tms()
            //   let vars=''
            //   let par={scm_code:this.$store.state.currentMartId,ip:this.$store.state.ip,supcustNo:this.tableData.superCode,branchNo:this.tableData.BranchNo,unionid:this.$store.state.unionid}
            //   if(val!=''){
            //       vars="branchNo="+this.tableData.BranchNo+"&ip="+this.$store.state.ip+"&itemName="+val+"&unionid="+this.$store.state.unionid+"&scm_code="+this.$store.state.currentMartId+"&supcustNo="+this.tableData.superCode
            //       par["itemName"]=val;
            //   }
            //   else{
            //        vars="branchNo="+this.tableData.BranchNo+"&ip="+this.$store.state.ip+"&unionid="+this.$store.state.unionid+"&scm_code="+this.$store.state.currentMartId+"&supcustNo="+this.tableData.superCode
            //   }

            //   let sign= md5.M5(vars)

               this.reqM1Service('/SCM/GetItem?sign='+sign,par,'post')
              //this.$axios.post('/SCM/GetItem?sign='+sign,par)
              .then((res=>{
                  console.log("开始获取goods",res.data)
                if(res.data.code==200){
                    if (res.data.data.length>0 )
                    {

                       this.tableData_goods=res.data.data;
                       ///如果记录只是一条就直接添加
                       if(res.data.data.length===1){

                         {
                            if(this.findval(this.tableData_goods[0].货号,this.tableData.Items)===true){
                                return;
                            }


                              console.log("开始添加记录")

                              this.tableData.Items.push({
                                Code: this.tableData_goods[0].货号,
                                SubCode:this.tableData_goods[0].自编码,
                                GoodsName: this.tableData_goods[0].品名,
                                LargeNum:0,
                                Num:0,
                                GiftsNum:0,
                                Price:this.tableData_goods[0].进价,
                                Total:0.00,
                                QtySpec:this.tableData_goods[0].进货规格,
                                Spec:this.tableData_goods[0].规格,
                                StockQty:this.tableData_goods[0].库存,
                                Unit:this.tableData_goods[0].单位,
                                CurrentWeekSale:this.tableData_goods[0].本周销量,
                                LastWeekSale:this.tableData_goods[0].上周销量,
                                CurrenMonthSale:this.tableData_goods[0].本月销量,
                                LastMonthSale:this.tableData_goods[0].上月销量,
                                Memo:this.tableData_goods[0].状态,
                              }

                              )
                          }

                       }
                       //如果是多条记录，就打开选择窗口。
                       else{
                           this.goods_dialogVisible=true;
                       }
                    }

                }
                }
              ))

            },
            openOldsheet(){
                 if(this.parameter.length>10)
                 {
                    let url='/SCM/GetOnLinePODetail?sign=';
                    let _this=this;
                    let Query={
                        sheetNo:_this.parameter,
                        supcustNo:_this.$store.state.superCode,
                        scm_code:_this.$store.state.currentMartId,
                        ip:_this.$store.state.ip,
                        unionid:this.$store.state.unionid
                    }

                    let newkey = objKeySort(Query)
                    let par={};
                    let md5s='';
                     for (let i in newkey)
                    {

                      if (newkey[i]!='')
                      {
                        md5s+=i+"="+newkey[i]+"&";
                        par[i]=newkey[i];
                      }

                    }
                    if(md5s.length>1){
                      md5s=md5s.slice(0,-1)
                    }
                    let sign= md5.M5(md5s)
                     _this.reqM1Service(url+sign,par,'post')
                    //_this.$axios.post(url+sign,par)
                    .then((res=>{
                        console.log(res.data.data)
                        if(res.data.code===200){
                            let main= res.data.data
                            let detail= res.data.data.items

                            _this.tableData.SheetNo=main.单号;
                            _this.tableData.BranchNo=main.仓库编码;
                            _this.tableData.SendDate=main.交货期限;
                            _this.tableData.OperDate=main.日期;
                            _this.tableData.Total=main.订金金额;
                            _this.tableData.Description=main.描述
                            _this.tableData.Memo=main.备注
                            _this.tableData.Status=main.审核标识
                            if(main.审核标识==='已审核'){
                                _this.sub_but=true;
                            }
                            else{
                                _this.sub_but=false;
                            }
                            ////添加明细
                            detail.forEach(row => {
                                _this.tableData.Items.push({
                                Code: row.货号,
                                SubCode:row.自编码,
                                GoodsName: row.品名,
                                LargeNum:row.箱数,
                                Num:row.数量,
                                GiftsNum:row.赠品数,
                                Price:row.价格,
                                Total:row.金额,
                                QtySpec:row.进货规格,
                                Spec:row.规格,
                                StockQty:row.库存,
                                Unit:row.单位,
                                CurrentWeekSale:row.本周销量,
                                LastWeekSale:row.上周销量,
                                CurrenMonthSale:row.本月销量,
                                LastMonthSale:row.上月销量,
                                Memo:row.备注,}

                                )

                            });
                            _this.getTotal();





                        }
                    }))


                 }
            },

////获取门店列表
            initselect(){

              this.supcustNo =this.$store.state.superCode
              //加载结构json
              let tmp = md5.tms()
              let vars='ip='+this.$store.state.ip+"&unionid="+this.$store.state.unionid+"&scm_code="+this.$store.state.currentMartId+"&supcustNo="+this.$store.state.superCode
               let par={ip:this.$store.state.ip,
              scm_code:this.$store.state.currentMartId,
              unionid:this.$store.state.unionid,
              supcustNo:this.$store.state.superCode}
              let newkey = PostobjKeySort(par)


             let sign= md5.M5(newkey.md5s)
              this.reqM1Service('/SCM/GetBranch?sign='+sign,newkey.par,'post')
              //this.$axios.post('/SCM/GetBranch?sign='+sign,par)
              .then((res=>{
                  console.log("我这里获取门店列表",res.data.data)

                if(res.data.code==200){
                  this.tableData_shop=res.data.data
                }
                }
              ))

              },
              //check is all select
              handleSelectionChange(val) {
            this.multipleSelection = val;
            },
             confirm_shop(){
              this.tableData.BranchNo=this.currentShop;
              this.Branch_dialogVisible = false;

            },
            getTotal(){
              let Items=  this.tableData.Items;
              this.tableData.Total=0.00;
              this.tableData.Total=parseFloat(this.tableData.Total)

              Items.forEach(row => {
                  this.tableData.Total+=parseFloat(row.Total)
              });
            },
            changeAmount (col,row) {

            row[col] = row[col].replace(/[^0-9.]/g,'')
            // if (row[col]===''){
            //     row[col]='0.00'
            // }
                        },
            handleCurrentChange(row, event, column) {
                console.log(row, event, column, event.currentTarget)
            },
            handleEdit(index,v, row) {

                if (v==='LargeNum'){
                    console.log('我在计num',row.LargeNum.replace(/[^0-9.]/g,''))
                    row.Num=row.LargeNum.replace(/[^0-9.]/g,'')*row.QtySpec;
                    row.Total=(row.Num*row.Price).toFixed(2)
                }
                 if (v==='Num'){

                     console.log('我在计算largenum',row.Num.replace(/[^0-9.]/g,''))

                    row.LargeNum=row.Num.replace(/[^0-9.]/g,'')/row.QtySpec;
                    row.Total=(row.Num.replace(/[^0-9.]/g,'')*row.Price).toFixed(2)
                }
                this.getTotal()
            },

            //门店选择事件
            handleCurrentChange_shop(val) {

        this.currentShop=val.编码
      },
        }

    }
</script>
    <style>
        * {
    margin: 0;
    padding: 0
}
  .left-span{

      text-align:right;
      line-height: 26px;
      font-size: 13px;

  }
  .left-span-content{
        line-height: 26px;
        font-size: 13px;
        min-height: 25px;
        border-radius: 0px;
        border-bottom: 1px solid #999;
        }
  .el-row {
    margin-bottom: 3px;
}
body {
    font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
    overflow: auto;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}
.tb-edit .el-input {
    display: none
}
.tb-edit .current-row .el-input {
    display: block
}
.tb-edit .current-row .el-input+span {
    display: none
}
 .el-table td {
  padding: 4px 0 !important;
}
.el-table .cell{
    padding-left: 5px;
    padding-right: 1px;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 140px;
}
.fe{
    height: 100%;
}
.title2{
    font-size:small;
    margin-left: 5px;
    margin-right: 5px;
}

    </style>